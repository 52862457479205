import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "16px"
  }
};
export function render(_ctx, _cache) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_form, {
    onSubmit: _ctx.onSubmit
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell_group, {
      inset: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_van_field, {
        modelValue: _ctx.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.username = $event),
        name: "用户名",
        label: "用户名",
        placeholder: "用户名",
        rules: [{
          required: true,
          message: '请填写用户名'
        }]
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.password = $event),
        type: "password",
        name: "密码",
        label: "密码",
        placeholder: "密码",
        rules: [{
          required: true,
          message: '请填写密码'
        }]
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "primary",
      "native-type": "submit"
    }, {
      default: _withCtx(() => [_createTextVNode(" 提交 ")]),
      _: 1
    })])]),
    _: 1
  }, 8, ["onSubmit"])]);
}